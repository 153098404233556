@import "~include-media/dist/include-media";
@import '../../styles/common';


.imageSliderClassContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: rem(50) 0;
}
.compareWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    position: relative !important;
    object-fit: cover;
  }

  @media (max-width: map_get($breakpoints, xs)) {
    padding: 0 rem(legacy-rem(12));
  }
  .deviceFrame {
    z-index: 1;
    display: block;
    width: rem(legacy-rem(1628));
    position: relative;
    & > span {
      img {
        @media only screen and (min-width:768px) {
          top: -4% !important;
          min-height: 95% !important;
        }
        @media only screen and (max-width:768px) {
          top:rem(legacy-rem(-20)) !important;
          min-height: 95% !important;
        }
        @media only screen and (max-width: 460px) {
          top: rem(legacy-rem(-9)) !important;
          min-height: 96% !important;
          left:rem(legacy-rem(100));
        }

      }
    }
    .screen {
      position: absolute;
      cursor: grab;
      left: 13.5%;
      width: 73.05%;
      top: 2.4%;
      height: 79.5%;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-top-left-radius: 3% 5%;
      border-top-right-radius: 3% 5%;
      border-bottom-left-radius: 1.3% 2%;
      border-bottom-right-radius: 1.3% 2%;

    }
  }
  .deviceFrameMobile {
    z-index: 1;
    display: block;
    width: rem(460);
    position: relative;
    .screen {
      position: absolute;
      cursor: grab;
      left: 5.6%;
      width: 88.5%;
      top: 10.5%;
      height: 79%;
      display: flex;
      align-items: center;
      overflow: hidden;

    }
  }
}
