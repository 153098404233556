.constrained {
  &--left {
    margin-right: auto;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--right {
    margin-left: auto;
  }
}