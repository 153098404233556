@import "~include-media/dist/include-media";
@import '../../styles/common';

.inlineLogo {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  @media (max-width: map_get($breakpoints, md)) {
    width: 100%;
    justify-content: center;
    margin: 20px 0 40px 0;
  }
  @media (max-width: map_get($breakpoints, sm)) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .listItem {
    width: rem(legacy-rem(65));
    height: rem(legacy-rem(65));
    list-style: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    img {
      width: 100%;
    }

    @media (max-width: map_get($breakpoints, xs)) {
      width: rem(legacy-rem(60));
      height: rem(legacy-rem(60));
    }
  }
}
