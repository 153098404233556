@import "~include-media/dist/include-media";
@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/functions';

.listStyleRed::marker {
  color: $secondary-red;
}

.listStyleYellow::marker {
  color: $secondary-yellow;
}

.listStyleOrange::marker {
  color: $secondary-orange;
}

.listStylePurple::marker {
  color: $secondary-purple;
}

.listStyleGreen::marker {
  color: $secondary-green;
}

.list-item {
  padding-left: 0;
  flex-direction: row;
  position: relative;
  align-items: center;
  font-weight: 300;
  color: $secondary-darkgrey2;
  margin-bottom: rem(legacy-rem(5));

  @include media('>=md') {
    margin-bottom: rem(legacy-rem(10));
  }

  p {
    margin-bottom: 0;
  }

  &__order {
    flex: 1;
    text-align: center;
    max-width: rem(legacy-rem(19));
    min-width: rem(legacy-rem(19));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    margin-left: rem(legacy-rem(12));
    color: $secondary-darkgrey2;

    &--link {
      a {
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        transition: all .2s ease-in-out;
        color: $secondary-darkgrey2;
      }

      &-red:hover a {
        text-decoration-color: $secondary-red;
      }

      &-yellow:hover a {
        text-decoration-color: $secondary-yellow;
      }

      &-orange:hover a {
        text-decoration-color: $secondary-orange;
      }

      &-purple:hover a {
        text-decoration-color: $secondary-purple;
      }

      &-green:hover a {
        text-decoration-color: $secondary-green;
      }
    }

  }
}
