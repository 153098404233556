@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/functions';
@import "~include-media/dist/include-media";

.list-item {
  background-color: transparent;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: rem(legacy-rem(8));

  p {
    margin-bottom: 0;
  }

  &__icon-wrapper {
    flex: 1;
    text-align: center;
    max-width: rem(legacy-rem(24));
    min-width: rem(legacy-rem(24));
    max-height: rem(legacy-rem(24));
    min-height: rem(legacy-rem(24));

    display: flex;
    justify-content: center;
    position:relative;

    @media (min-width: map_get($breakpoints, lg)) {
      margin-top: rem(6);
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80%;
    margin: 10% 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }

    &--red {
      fill: $secondary-red;
    }

    &--yellow {
      fill: $secondary-yellow;
    }

    &--orange {
      fill: $secondary-orange;
    }

    &--purple {
      fill: $secondary-purple;
    }

    &--green {
      fill: $secondary-green;
    }

    &--blue {
      fill: $brand-blue;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-left: rem(legacy-rem(12));
    font-weight: 300;
    color: $secondary-darkgrey2;

    > * {
      font-weight: 300;
      color: $secondary-darkgrey2;
    }

    &--link {

       p {
        cursor: pointer;
        text-decoration: none !important;
        transition: all .2s ease-in-out;
        position: relative;


          &::before {
            background: #4A4A4A;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 1px;
            display: block;
            content: '';
          }

          &::after {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 0;
            height: 1px;
            background: $brand-blue;
            display: block;
            content: '';
            transition: width 0.5s ease-in-out;
          }

          &:hover {
            color: $brand-blue;

            &::after {
              width: 100%;
            }
          }
      }

      &-orange {
        & p:hover {
          color: $secondary-orange;
        }

        & p::after {
          background: $secondary-orange;
        }
      }

      &-red {
        & p:hover {
          color: $secondary-red;
        }

        & p::after {
          background: $secondary-red;
        }
      }

      &-blue {
        & p:hover {
          color: $brand-blue;
        }

        & p::after {
          background: $brand-blue;
        }
      }

      &-green {
        & p:hover {
          color: $secondary-green;
        }

        & p::after {
          background: $secondary-green;
        }
      }

      &-purple {
        & p:hover {
          color: $secondary-purple;
        }

        & p::after {
          background: $secondary-purple;
        }
      }

      &-yellow {
        & p:hover {
          color: $secondary-yellow;
        }

        & p::after {
          background: $secondary-yellow;
        }
      }
    }
  }
}

.list-item-button {
  background-color: transparent;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;

  p {
    margin-bottom: 0;
  }

  &__icon-wrapper {
    flex: 1;
    text-align: center;
    min-width: rem(legacy-rem(80));
    max-width: rem(legacy-rem(80));
    display: flex;
    justify-content: center;
    position:relative;
  }

  &__icon {
    flex: 1;
    position: absolute;
    top:0;
    bottom:0;
    width: 100%;
    padding: 35%;
    display: flex;
    justify-content: center;
    transition: all .2s ease-in-out;
    fill: $secondary-darkgrey2;

    @include media(">=lg") {
      padding: 30%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    fill: #F4F4F4;
    transition: all .2s ease-in-out;

    &__rotate_90 {
      transform: rotate(90deg);
    }
    &__rotate_180 {
      transform: rotate(180deg);
    }
    &__rotate_270 {
      transform: rotate(270deg);
    }
  }

  &__content {
    display: flex;
    margin-left: rem(legacy-rem(8));
    font-weight: 300;
    color: $secondary-darkgrey2;
    transition: all .2s ease-in-out;

    p {
      margin-bottom: 0;
      font-weight: 300;
      color: $secondary-darkgrey2;
    }

    &--link {
      cursor: pointer;
      text-decoration: underline;
      text-underline-position: under;

      p {
        transition: all .2s ease-in-out;
      }
    }
  }
}

.list-item-button--link:hover {
  cursor: pointer;
  transition: all .2s ease-in-out;

  .list-item-button__icon--link {
    fill: #F4F4F4;
  }

  .list-item-button__circle--link-red {
    fill: $secondary-red;
  }

  .list-item-button__circle--link-yellow {
    fill: $secondary-yellow;
  }

  .list-item-button__circle--link-orange {
    fill: $secondary-orange;
  }

  .list-item-button__circle--link-purple {
    fill: $secondary-purple;
  }

  .list-item-button__circle--link-green {
    fill: $secondary-green;
  }

  .list-item-button__circle--link-blue {
    fill: $brand-blue;
  }
}

.iconBlobLink {
  position: relative;
  width: 100%;
}
