@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/functions";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: rem(legacy-rem(20px));

  .embededVideoContainer {
    margin: 0 auto;
    mask-image: url('../../../public/images/relatedContentMask.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('../../../public/images/relatedContentMask.svg');
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;

    .videoContainer {
      overflow: hidden;
      border-radius: 25px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (max-width: map_get($breakpoints, lg)) {
      height: 100%;
    }
  }

  @media (max-width: map_get($breakpoints, lg)) {
    width: rem(legacy-rem(674));
    height: rem(legacy-rem(450));
  }

  @media (max-width: map_get($breakpoints, md)) {
    width: rem(legacy-rem(440));
    height: rem(legacy-rem(320));
  }

  @media (max-width: map_get($breakpoints, sm)) {
    width: rem(legacy-rem(337));
    height: rem(legacy-rem(225));
  }
}
