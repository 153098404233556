@use "sass:math";

@import "~include-media/dist/include-media";
@import '../../../../styles/common';

.standard {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(100);

  @include media(">=md") {
    flex-direction: row;

    & > section {
      margin-right: rem(50);
    }

    & > section:last-child {
      margin-right: unset;
    }
  }

  @include media(">=lg") {
    padding: 0 rem(legacy-rem(105));
  }
}
@include media(">=lg") {
  .projectConstrained {
    margin: 0 rem(107) !important;
  }
}

.rowReverse {
  @include media(">=lg") {
    flex-direction: row-reverse;

    & > section {
      margin-left: rem(50);
      margin-right: unset;
    }

    & > section:last-child {
      margin-left: unset;
    }
  }
}

.blogConstrained {
  @include media(">=lg") {
    max-width: #{math.div(8.5, 12) * 100%} !important;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(">=xl") {
    max-width: #{math.div(7.2, 12) * 100%} !important;
    margin-left: auto;
    margin-right: auto;
  }
}


.constrained {
  @include media(">=lg") {
    max-width: #{math.div(9, 12) * 100%} !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.leftOverflow {
  @include media(">=lg") {
    padding-left: unset !important;
  }
}

.rightOverflow {
  @include media(">=lg") {
    padding-right: unset;
  }
}

.alignVertical {
  @include media(">=lg") {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.wide {
  @include media(">=lg") {
    padding: unset !important;
  }
}

.standardGap {
  @include media(">=md") {
    column-gap: rem(45);
  }
}

.extraGap {
  @include media(">=lg") {
    & > section {
      margin-right: rem(130);
    }

    & > section:last-child {
      margin-right: unset;
    }
  }
}

.extraExtraGap {
  @include media(">=md") {
    & > section {
      margin-right: rem(130);
    }

    & > section:last-child {
      margin-right: unset;
    }
  }
}

.fourtyWidth {
  @include media(">=lg") {
    & > section {
    flex: none;
    width: 40%
    }
  }
}

.fiftyWidth {
  @include media(">=lg") {
    & > section {
    flex: none;
    width: 50%
    }
  }
}

.sixtyWidth {
  @include media(">=lg") {
    & > section {
    flex: none;
    width: 60%
    }
  }
}

.seventyWidth {
  @include media(">=lg") {
    & > section {
    flex: none;
    width: 70%
    }
  }
}

.eightyWidth {
  @include media(">=lg") {
    & > section {
    flex: none;
    width: 80%
    }
  }
}
