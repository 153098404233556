@use "sass:math";

@import "~include-media/dist/include-media";
@import '../../styles/common.scss';

.imgWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  div > div {
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  .fullWidth {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .imgType {
    &-default {
      img {
        width: 100%;
      }
    }

    &-rectangle {
      img {
        mask-image: url('../../../public/images/masks/fcp_image_rectangle_mask.svg');
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        -webkit-mask-image: url('../../../public/images/masks/fcp_image_rectangle_mask.svg');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        max-width: 100%;
      }
    }

    &-circle {
      img {
        mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 100% 100%;
        -webkit-mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-size: 100% 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
    }
  }

  .imgSize {

    &-default {
      img {
        width: 100%;
      }
    }

    &-90 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 90%;
      }
    }

    &-80 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 80%;
      }
    }

    &-70 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 70%;
      }
    }

    &-60 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 60%;
      }
    }

    &-50 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 50%;
      }
    }

    &-40 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 40%;
      }
    }

    &-30 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 30%;
      }
    }

    &-20 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 30%;

        @include media(">=md") {
          width: 20%;
        }
      }
    }

    &-10 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 30%;

        @include media(">=md") {
          width: 10%;
        }
      }
    }

    &-5 {
      @include media("<md") {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      img {
        width: 30%;

        @include media(">=md") {
          width: 5%;
        }
      }
    }
  }

  .imgMobSize {

    &-default {
      @include media("<md") {
        img {
          width: 100%;
        }
      }
    }

    &-90 {
      @include media("<md") {
        img {
          width: 90%;
        }
      }
    }

    &-80 {
      @include media("<md") {
        img {
          width: 80%;
        }
      }
    }

    &-70 {
      @include media("<md") {
        img {
          width: 70%;
        }
      }
    }

    &-60 {
      @include media("<md") {
        img {
          width: 60%;
        }
      }
    }

    &-50 {
      @include media("<md") {
        img {
          width: 50%;
        }
      }
    }

    &-40 {
      @include media("<md") {
        img {
          width: 40%;
        }
      }
    }

    &-30 {
      @include media("<md") {
        img {
          width: 30%;
        }
      }
    }

    &-20 {
      @include media("<md") {
        img {
          width: 20%;
        }
      }
    }

    &-10 {
      @include media("<md") {
        img {
          width: 10%;
        }
      }
    }

    &-5 {
      @include media("<md") {
        img {
          width: 5%;
        }
      }
    }
  }

  .imageTitle {
    text-align: center;
    padding: 5px 0;
    font-size: rem(legacy-rem(14));
    color: $secondary-lightgrey;
  }
}

.imgAlign {
  &-center {
    div > div {
      display: flex;
      justify-content: center;
    }
  }

  &-left {
    div > div {
      display: flex;
      justify-content: flex-start;
    }
  }

  &-right {
    div > div {
      display: flex;
      justify-content: flex-end;
    }
  }
}
