@import "~include-media/dist/include-media";
@import "../../styles/common";

.dashed_ul {
    padding-left: rem(legacy-rem(25));
    margin: 0;
    background-image: url('../../../public/images/Dashed_List_Border.svg');
    background-repeat: repeat-y;
    list-style: none;

    li {

      strong {
        font-size: rem(legacy-rem(25));
      }
      a {
        color: black;
        text-decoration: underline;
        text-underline-position: under;
        transition: all .5s ease-in-out;
        color: $secondary-darkgrey2;

      &:hover {
        text-decoration-color:  $primary-lightblue;
      }
    }

      &:last-child {
        padding-bottom: rem(legacy-rem(5));

        @include media(">=md") {
          padding-bottom: rem(legacy-rem(10));
        }
      }
    }
}

@include media(">=md") {
  .strongLi {
    margin-bottom: rem(legacy-rem(50)) !important;
  }

  .normalLi {
    margin-bottom: rem(legacy-rem(10)) !important;
  }
}