@import "../../styles/common";
@import "~include-media/dist/include-media";

.column {
  display: block;
  max-width: 100%;
  padding: 0;
  height: 100%;

  a {
    text-decoration: underline;
    text-underline-position: under;
    transition: all .5s ease-in-out;
    color: $secondary-darkgrey2;

    &:hover {
      text-decoration-color: $brand-pink;
    }
  }

  @include remove-last-child-margins;

  p {
    overflow-wrap: break-word;
    width: 100%;

    b {
      @include media(">=lg") {
        font-size: 1.125rem;
      }
    }
  }

  p > img {
    width: 100%;
  }

  @media (max-width: map_get($breakpoints, lg)) {
    margin: 0 0 rem(legacy-rem(25)) 0;

    @include remove-last-child-margins;

    h2, h3, h4, h5, h6 {
      margin-bottom: rem(25);
    }

    display:inline-block;
  }
}
