@import "~include-media/dist/include-media";
@import '../../styles/common';


.formContainer {
  width: 70%;
  padding: 0;
  margin: rem(100) auto rem(50);

  @include media("<lg"){
    width: 80%;
  }

  @include media("<sm"){
    width: 90%;
  }

  .formWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.formFieldsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.formFieldWrapper {
  width: 46%;
  margin-bottom: rem(legacy-rem(65));
  @media (max-width: map_get($breakpoints, md)) {
    width: 100%;
  }

  @include media("<md"){
    margin-bottom: rem(legacy-rem(40));
  }

  &:not(.fullWidth) {
    &:nth-child(odd) {
      margin-right: 8%;
      @media (max-width: map_get($breakpoints, md)) {
        margin-right: 0;
      }
    }
  }

  &.fullWidth {
    width: 100%;
    display: flex;
    margin-left: 0;
  }

  .checkBoxGroupTitle {
    @include custom-font-style($formInputFont, rem(legacy-rem(16)), $primary, false, rem(legacy-rem(35)));
    margin-bottom: rem(legacy-rem(25));

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    .required {
      @include custom-font-style($formInputFont, rem(legacy-rem(18)), $brand-pink, false, rem(legacy-rem(35)));
    }
  }
}

.inputFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    @include custom-font-style($formInputFont, rem(legacy-rem(16)), $primary, false, rem(legacy-rem(35)));
    margin-bottom: rem(legacy-rem(25));

    @include media("<md"){
      margin-bottom: 0;
    }

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    .required {
      @include custom-font-style($formInputFont, rem(legacy-rem(18)), $brand-pink, false, rem(legacy-rem(35)));
    }
  }

  .textInput,
  .selectInput,
  .datePicker{
    @include custom-font-style($bodyFont, rem(legacy-rem(16)), $secondary-darkgrey2, false, rem(legacy-rem(35)));
    width: 100%;
    margin-bottom: rem(legacy-rem(10));
    border: none;
    border-bottom: solid 1px $secondary-lightgrey;
    transition: all 0.3s;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border-radius: 0;

    &.fileInput {
      border-bottom: none;
      &:hover,
      &:focus,
      &:focus-within{
        border-bottom: none;
      }
    }

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    &::placeholder {
      color: $secondary-lightgrey2;
    }

    &:hover,
    &:focus,
    &:focus-within{
      outline: none;
      border: none;
      border-bottom: solid 1px $primary-lightblue;
    }

    &.error {
      color: $brand-pink;
      border-bottom: solid 1px $brand-pink;

      &.fileInput {
        border-bottom: none;
        &:hover,
        &:focus,
        &:focus-within{
          border-bottom: none;
        }
      }


      &:hover,
      &:focus,
      &:focus-within{
        color: $secondary-darkgrey2;
        border-bottom: solid 1px $primary-lightblue;
      }
    }
  }

  .textAreaInput {
    @include custom-font-style($bodyFont, rem(legacy-rem(16)), $secondary-darkgrey2, false, rem(legacy-rem(35)));
    width: 100%;
    margin-bottom: rem(legacy-rem(10));
    padding: rem(legacy-rem(10)) rem(legacy-rem(20));
    transition: all 0.3s;
    height: rem(legacy-rem(200));
    resize: both;
    border: solid 1px $secondary-lightgrey;
    background: $secondary-grey;
    border-radius: 0;

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    &::placeholder {
      color: $secondary-lightgrey2;
    }

    &:hover,
    &:focus,
    &:focus-within{
      outline: none;
      border-color: $primary-lightblue;
    }

    &.error {
      color: $brand-pink;
      border-color: $brand-pink;
      &:hover,
      &:focus,
      &:focus-within{
        color: $secondary-darkgrey2;
        border-color: $primary-lightblue;
      }
    }
  }
  .checkMark,
  .circleMark{
    &:after {
      content: "";
      display: none;
    }
  }

  /* Checkbox custom styles */
  .checkBoxInputWrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    @include custom-font-style($bodyFont, rem(legacy-rem(16)), $secondary-darkgrey2, false, rem(legacy-rem(35)));
    margin-bottom: rem(legacy-rem(10));

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    input {
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkMark {
        background-color: $primary-lightblue;
        border: none;
        &:after {
          display: block;
        }
      }
    }

    .checkMark {
      min-height: 18px;
      min-width: 18px;
      border: solid 1px $secondary-darkgrey3;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      margin-right: rem(legacy-rem(12));

      &:after {
        width: 3px;
        height: 7px;
        border: solid white;
        border-width: 0 rem(legacy-rem(3)) rem(legacy-rem(3)) 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-bottom: 2px;
      }
    }

    &:hover input ~ .checkMark {
      background-color: $primary-lightblue;
      border: none;
    }
  }

  /* Radio button custom styles */
  .radioInputWrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    @include custom-font-style($bodyFont, rem(legacy-rem(16)), $secondary-darkgrey2, false, rem(legacy-rem(35)));

    @media (min-width: map_get($breakpoints, md)) {
      font-size: rem(legacy-rem(18));
    }

    input {
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .circleMark {
        background-color: $primary-lightblue;
        border: none;
        &:after {
          display: block;
        }
      }
    }

    .circleMark {
      height: 18px;
      width: 18px;
      border: solid 1px $secondary-darkgrey3;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      margin-right: rem(legacy-rem(12));

      &:after {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
    }

    &:hover input ~ .circleMark {
      background-color: $primary-lightblue;
      border: none;
    }
  }

  &.datePickerWrapper {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
      width: 100%;
    }

    .datePicker {
      width: 100%;
    }

    .datePickerItem {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
  }
}

.errorText {
  @include custom-font-style($bodyFont, rem(legacy-rem(15)), $brand-pink, false, rem(legacy-rem(35)));
  text-align: left;
  margin-bottom: rem(legacy-rem(10));
}
