@import "../../styles/functions";
@import "~include-media/dist/include-media";
@import "../../styles/colors";

.quoteVariant {
    display: block;
    .quote {
        font-weight: bold;
        display: block;
        font-size: rem(legacy-rem(28));
        line-height: rem(legacy-rem(55));
        color: $primary;
        text-decoration: underline;
        text-decoration-color: #fdf2b3;
        text-decoration-thickness: rem(legacy-rem(10));

        @media (max-width: map_get($breakpoints, md)) {
            font-size: rem(legacy-rem(17));
            line-height: rem(legacy-rem(30));
            margin-bottom: rem(legacy-rem(10));
        }
    }
    .wrapper {
        font-family: $bodyFont;
        .author {
            font-weight: $bold;
            font-size: rem(legacy-rem(20));
            margin-right: rem(legacy-rem(12));
            line-height: rem(legacy-rem(45));

            @media (max-width: map_get($breakpoints, md)) {
                font-size: rem(legacy-rem(13));
            }
        }
        .position {
            line-height: rem(legacy-rem(45));
            font-weight: $medium;
            font-size: rem(legacy-rem(20));

            @media (max-width: map_get($breakpoints, md)) {
                font-size: rem(legacy-rem(13));
            }
        }
    }
}
.quoteVariant_inner {
    display: block;

    .quote {
        display: block;
        font-size: rem(legacy-rem(40));
        line-height: rem(legacy-rem(55));
        color: $white;
        text-decoration: underline solid #fdf2b3 12px;

        @media (max-width: map_get($breakpoints, md)) {
            font-size: rem(legacy-rem(17));
            line-height: rem(legacy-rem(30));
            margin-bottom: rem(legacy-rem(10));
        }
    }
    .wrapper {
        font-family: $bodyFont;
        .author {
            font-weight: $bold;
            font-size: rem(legacy-rem(20));
            margin-right: rem(legacy-rem(12));
            line-height: rem(legacy-rem(45));
            color: $secondary-lightgrey3;

            @media (max-width: map_get($breakpoints, md)) {
                font-size: rem(legacy-rem(13));
            }
        }
        .position {
            line-height: rem(legacy-rem(45));
            font-weight: $medium;
            font-size: rem(legacy-rem(20));
            color: $secondary-lightgrey3;

            @media (max-width: map_get($breakpoints, md)) {
                font-size: rem(legacy-rem(13));
            }
        }
    }
}
